import { Component, OnInit, Inject, PLATFORM_ID, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { TreeService } from '../../services/tree.service';
import { FileService } from '../../services/file.service';
import { MetaService } from '../../services/meta.service';
import { isPlatformBrowser } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { IgDialogComponent } from '../ig-dialog/ig-dialog.component';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

	defaultImage = environment.lazyLoad_defaultImg;
	filepath = environment.FileUrl;

	//angular loading完才顯示區塊
	after_loading;

	//區塊開關控制
	setting; 


    data;
    ig;
    ig_1 = [0,1,2,3,4];
    ig_2 = [5,6,7,8,9];
 	mobile;


    // 認識產品
    product_option = {
      // loop: true,
      lazyLoad: true,
      // dots: false,
      merge:true,
      navText: ['<img src="/assets/images/icon/chevron-left.svg">', '<img src="/assets/images/icon/chevron-right.svg">'],
      responsive: {
        0: {
          margin: 0,
          stagePadding: 20,
          items: 1,
          nav: false,
          dots: true,
          mergeFit: true,
          slideBy: 1,
        },
        708: {
          margin: 0,
          stagePadding: 20,
          items: 4,
          nav: false,
          dots: true,
          mergeFit: false,
          slideBy: 4,
        },
        872: {
          margin: 0,
          stagePadding: 50,
          items: 4,
          nav: true,
          dots: false,
          mergeFit: false,
          slideBy: 4,
        },
        1310: {
          margin: 0,
          stagePadding: 50,
          items: 4,
          nav: true,
          dots: false,
          mergeFit: false,
          slideBy: 4,
        },
      },
    };

    // 產地植人
    origin_option = {
      loop: true,
      lazyLoad: true,
      // dots: false,
      navText: ['<img src="/assets/images/icon/chevron-left.svg">', '<img src="/assets/images/icon/chevron-right.svg">'],
      responsive: {
        0: {
          margin: 0,
          stagePadding: 0,
          items: 1,
          nav: false,
          dots: true
        },
        708: {
          margin: 15,
          stagePadding: 0,
          items: 2,
          nav: false,
          dots: true
        },
        872: {
          margin: 20,
          stagePadding: 0,
          items: 3,
          nav: true,
          dots: false
        },
      },
    };

    // 購買產品
    buy_option = {
      // loop: true,
      lazyLoad: true,
      // dots: false,
      navText: ['<img src="/assets/images/icon/chevron-left.svg">', '<img src="/assets/images/icon/chevron-right.svg">'],
      responsive: {
        0: {
          margin: 0,
          stagePadding: 0,
          items: 1,
          nav: false,
          dots: true
        },
        708: {
          margin: 12,
          stagePadding: 0,
          items: 4,
          nav: false,
          dots: true
        },
        872: {
          margin: 24,
          stagePadding: 0,
          items: 5,
          nav: false,
          dots: false
        },
      },
    };

    // 顧客見證
    gallery_option = {
      // loop: true,
      lazyLoad: true,
      // dots: false,
      navText: ['<img src="/assets/images/icon/chevron-left.svg">', '<img src="/assets/images/icon/chevron-right.svg">'],
      responsive: {
        0: {
          margin: 0,
          stagePadding: 0,
          items: 1,
          nav: false,
          dots: true
        },
        708: {
          margin: 0,
          stagePadding: 0,
          items: 1,
          nav: false,
          dots: true
        },
        872: {
          margin: 0,
          stagePadding: 0,
          items: 1,
          nav: true
        },
      },
    };


    //主題商品類別
    topics;
    topic_index = 0;

  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		private translate: TranslateService,
        private httpService: HttpService,
        private treeService: TreeService,
        private metaService: MetaService,
        public sanitizer: DomSanitizer,
        private dialogService: NbDialogService,
        public fileService: FileService
  	) { }

  	ngOnInit(): void {
        this.getData();

  		if(isPlatformBrowser(this.platformId)){
	        if (window.screen.width < 992) {
	          this.mobile = true
	        }
	        this.after_loading = true;
	        
	    }
  	}



    //取得首頁資訊
    getData(){
        let url = `${environment.APIUrl}/api/lab-web-content/front/home-data`;
        
        this.httpService.getHttp(url).subscribe(
            res => {
            	
                this.data = res;

                //SEO
                if(res.metadata){
                	this.metaService.setIndexMetaByData(res.metadata)
                }
               	

                //IG牆
                if(isPlatformBrowser(this.platformId)){
		            
	                if(isPlatformBrowser(this.platformId)){
		                res.ig_items.map(item=>{
		                	item.url = this.sanitizer.bypassSecurityTrustHtml(item.url)
		                })
		            }
		            this.ig = res.ig_items;
		        }
	                
	                
            }
        )
    }


    //IG popup
    openIGDialog(index) {
    	this.dialogService.open(IgDialogComponent, 
    		{ 
    			context: {
    				ig: this.ig,
    				index: index
    			}
    		}
    	);
      // document.querySelector('html').classList.add('overflow-hidden');
  	}


  	ngOnDestroy(){
  		this.metaService.setMetaByDefault()
  	}


   
}
