import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { NbSidebarService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

	user_info;

	shop = {
		id:localStorage.getItem('shop_id'),
		name:localStorage.getItem('shop_name')
	};



  	constructor(
  		private sidebarService: NbSidebarService,
  		public authService: AuthService
  	) { }

  	ngOnInit(): void {
  		this.user_info = this.authService.getUserInfo();
  	}


  	//左邊sidebar 開合
  	toggle() {
    	this.sidebarService.toggle();
  	}


  	

}
