import { Injectable, Renderer2 } from '@angular/core';
import { GtagService } from './gtag.service';
import { MetaPixelService } from './meta-pixel.service';

@Injectable({
  providedIn: 'root'
})
export class EventTrackingService {

  constructor(
    private gtagService: GtagService,
    private metaPixelService: MetaPixelService
  ) { }

  setupEventTracking(renderer2: Renderer2) {
    this.gtagService.setupScript(renderer2);
    this.metaPixelService.setupScript(renderer2);
  }

	// 將商品加入購物車
  etAddToCart(skus){
    this.gtagService.addToCart(skus);
    this.metaPixelService.addToCart(skus);
  }

	// 完成購買
  etPurchase(order_number, order_total) {
    this.gtagService.purchase(order_number, order_total);
    this.metaPixelService.purchase(order_number, order_total);
  }
}
