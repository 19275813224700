import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
import { CartService } from '../../services/cart.service';
import { HttpService } from '../../services/http.service';
import { FileService } from '../../services/file.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home-product-item',
  templateUrl: './home-product-item.component.html',
  styleUrls: ['./home-product-item.component.scss']
})
export class HomeProductItemComponent implements OnInit {

	@Input() item;
	@Input() type;
    @Input() link_type;
    @Input() url;

	filepath = environment.FileUrl;
	defaultImage = environment.lazyLoad_defaultImg;

	allowAddCart; //是否可以加入購物車

	//購買資訊
    product_id;
    selectOptions = {};
    selectSKU;
    selectNum = 1;
    mobile;

  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		public router: Router,
  		public cartService: CartService,
        private httpService: HttpService,
        public fileService: FileService
  	) { }

  	ngOnInit(): void {

  		this.product_id = this.item.id;

  		if(this.item.default_sku){
  			this.selectSKU = this.item.default_sku;
        	//this.selectOptions = this.item.default_sku.option_ids;
  		}
        

        //是否可以加入購物車
        this.allowAddCart = this.item.cart_status;
        if(this.allowAddCart==2){
            let start = new Date(this.item.cart_start_date).getTime();
            let end = new Date(this.item.cart_end_date).getTime();
            let today = new Date().getTime();
            if((today<start) || (today>end)){
                this.allowAddCart=0;
            }else{
                this.allowAddCart=1;
            }
        }

        if(isPlatformBrowser(this.platformId)){
	      	if(window.screen.width < 992){
	        	this.mobile = true;
	      	}
	  	}
  	}

  	

}
