import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class MetaService {

  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		@Inject(DOCUMENT) private dom,
  		private httpService:HttpService, 
  		private router: Router,
  		private meta: Meta, 
  		private title: Title
  	) { }


  	//設定預設web title
  	setMetaByDefault(){
  		
  		let metadata = {
  			title: environment.webTitle,
  			keyword: '',
  			description:　''
  		}
  		
  		//if(isPlatformBrowser(this.platformId)){
	  		this.meta.updateTag({ name: 'keyword', content: metadata.keyword },`name='keyword'`);
			this.meta.updateTag({ name: 'title', content: metadata.title },`name='title'`);
			this.meta.updateTag({ name: 'description', content: metadata.description },`name='description'`);
			this.meta.updateTag({ name: 'og:title', content: metadata.title },`name='og:title'`);
			this.meta.updateTag({ name: 'og:description', content: metadata.description },`name='og:description'`);
			this.title.setTitle(metadata.title);
		//}
  	}


  	//設定metadata
	setMetaById(model_type, model_id){
		let url = `${environment.APIUrl}/api/lab-web-content/front/metadata?model_type=${model_type}&model_id=${model_id}`;
		this.httpService.getHttp(url).subscribe(
			res => {

				if(res.metadata){
					this.meta.updateTag({ name: 'keyword', content: res.metadata.keyword },`name='keyword'`);
					this.meta.updateTag({ name: 'description', content: res.metadata.description },`name='description'`);
					this.meta.updateTag({ name: 'og:description', content: res.metadata.description },`name='og:description'`);
					
					if(res.metadata.title){
						this.title.setTitle(res.metadata.title);
						this.meta.updateTag({ name: 'title', content: res.metadata.title },`name='title'`);
						this.meta.updateTag({ name: 'og:title', content: res.metadata.title },`name='og:title'`);
					}else{
						this.title.setTitle(environment.webTitle);
					}
				}
				
			}
		);
	}


	//設定metadata
	setMetaByData(metadata){
		//console.log(metadata);
		if(metadata){
			this.meta.updateTag({ name: 'keyword', content: metadata.keyword },`name='keyword'`);
			this.meta.updateTag({ name: 'title', content: metadata.title },`name='title'`);
			this.meta.updateTag({ name: 'og:title', content: metadata.title },`name='og:title'`);
			this.meta.updateTag({ name: 'description', content: metadata.description },`name='description'`);
			this.meta.updateTag({ name: 'og:description', content: metadata.description },`name='og:description'`);

			this.title.setTitle(`${metadata.title} - ${environment.webTitle}`);

			if (metadata.canonical) {
				this.updateCanonicalUrl(metadata.canonical);
			} else {
				this.generateCanonicalForProductSkuPage();
			}
		}
	}


	// 設定首頁metadata
	setIndexMetaByData(metadata){
		//console.log(metadata);
		if(metadata){
			this.meta.updateTag({ name: 'keyword', content: metadata.keyword },`name='keyword'`);
			this.meta.updateTag({ name: 'title', content: metadata.title },`name='title'`);
			this.meta.updateTag({ name: 'og:title', content: metadata.title },`name='og:title'`);
			this.meta.updateTag({ name: 'description', content: metadata.description },`name='description'`);
			this.meta.updateTag({ name: 'og:description', content: metadata.description },`name='og:description'`);

			// 首頁title前面不加上env webTitle
			this.title.setTitle(metadata.title);
		}
	}

	updateCanonicalUrl(url:string){
		const head = this.dom.getElementsByTagName('head')[0];
		var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
		if (head && element === null) {
			element= this.dom.createElement('link') as HTMLLinkElement;
			head.appendChild(element);
		}
		element.setAttribute('rel','canonical')
		element.setAttribute('href',url)
	}

	generateCanonicalForProductSkuPage() {
		let path = this.router.url;
		const pathRegex: RegExp = new RegExp(`^/products/info_sku/`);
		if (pathRegex.test(path)) {
			path = path.replace('/products/info_sku/', '/products/info/');
			this.updateCanonicalUrl(`${environment.FRONT_URL}${path}`);
		}
	}

}
