//import { Injectable } from '@angular/core';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class CookieService {

	constructor(@Inject(PLATFORM_ID) private platformId: Object) { }


	//clear console log for BROWSER
	clearConsoleLog() {
		
		if (isPlatformBrowser(this.platformId)) {
	  		window.console.log = function () {}
	  		window.console.info = function () {}
	  		window.console.warn = function () {}
	  		window.console.error = function () {}
		}
	}
  


	setCookie(name: string, val: string) {
	    const date = new Date();
	    const value = val;

	    // Set it expire in 7 days
	    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

	    // Set it
	    // BROWSER
   		if (isPlatformBrowser(this.platformId)) {
	    	//document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
	    	document.cookie = name+"="+value+"; path=/";
	    }
	}

	getCookie(name: string) {
		// BROWSER
   		if (isPlatformBrowser(this.platformId)) {
		    const value = "; " + document.cookie;
		    const parts = value.split("; " + name + "=");
		    //console.log(parts);

		    if (parts.length == 2) {
		        return parts.pop().split(";").shift();
		    }else{
		    	return null;
		    }
		}
	}

	deleteCookie(name: string) {
	    const date = new Date();

	    // Set it expire in -1 days
	    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

	    // Set it
	    // BROWSER
   		if (isPlatformBrowser(this.platformId)) {
	    	//document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
	    	document.cookie = name+"=; path=/";
	    }
	}
}
