<section class="section-index pt-0" *ngIf="data">

	<app-banner *ngIf="data.home_top_banners" [list]="data.home_top_banners"></app-banner>

	<!--ng-container *ngIf="data.home_top_banner">
		<div class="banner">
			<a *ngIf="data.home_top_banner.link_type=='in'" [routerLink]="[data.home_top_banner.url]">
				<img [src]="filepath+data.home_top_banner.filepath">
			</a>
			<a *ngIf="data.home_top_banner.link_type=='out'" href="{{ data.home_top_banner.url }}" target="_blank">
				<img [src]="filepath+data.home_top_banner.filepath">
			</a>
			<img *ngIf="data.home_top_banner.link_type=='none'" [src]="filepath+data.home_top_banner.filepath">
		</div>
	</ng-container-->
	

	<div class="container">
		<div class="overlay-card">

			<div class="row g-4">

				<div class="col-md-6 col-lg-12">
					<div class="card" *ngIf="data.latest_news">
						<a [routerLink]="['/content/news/info', data.latest_news.id]">
							<div class="card-body">
								<div class="header">
									<h5>最新消息</h5>
									<div class="date">{{ data.latest_news.post_date }}</div>
								</div>
								<div class="card-image">
									<img [src]="filepath+fileService.getThumbnail(data.latest_news.image,'xs')">
								</div>
								<p class="card-text">{{ data.latest_news.title }}</p>
							</div>
						</a>
					</div>
				</div>

				<div class="col-md-6 col-lg-12">
					<div class="card" *ngIf="data.latest_blog">
						<a [routerLink]="['/content/blog/info', data.latest_blog.id]">
							<div class="card-body">
								<div class="header">
									<h5>生活提案</h5>
									<div class="date">{{ data.latest_blog.post_date }}</div>
								</div>
								<div class="card-image">
									<img [src]="filepath+fileService.getThumbnail(data.latest_blog.image,'xs')">
								</div>
								<p class="card-text">{{ data.latest_blog.title }}</p>
							</div>
						</a>
					</div>
				</div>

			</div>
			
		</div>
	</div>

</section>


<!-- 品牌理念 -->
<section class="section-index" id="index">
	<div class="container-fluid">

		<div class="section-header d-block d-md-flex">
			<h2 class="title title-underline">品牌理念</h2>
			<h5 class="text">優格，有多少種可能?</h5>
		</div>

		<div class="row g-4 g-md-0">
			<div class="col-md-5 col-lg-3">
				<div class="col-xl-8 mx-2 mx-md-3 text-sm">
					<p class="mb-4">我總相信，世界會因為善意的起心動念而改變…</p>
					<p>4,200個日子過去，我們重新定義優格，建立五感體驗儀式。我們選擇友善耕作植人、回歸人本精神。我們啟發消費者，相信品味才是生命的本質，我們相信深耕才能長久!</p>
					<br class="d-none d-lg-block">
					<br class="d-none d-xl-block">
					<br class="d-none d-xl-block">
					<br class="d-none d-xl-block">
					<br class="d-none d-xl-block">
					<a [routerLink]="['/content/page/brand']" class="btn btn-outline-dark mb-1">
						<span>詳閱更多</span><nb-icon icon="chevron-right"></nb-icon>
					</a>
				</div>
			</div>
			<div class="col-md-7 col-lg-9">
				<a [routerLink]="['/content/page/brand']">
					<picture>
					  <!-- mobile -->
					  <source media="(max-width: 991px)" srcset="/assets/images/index/idea_m.jpg" />
					  <!-- desktop -->
					  <img src="/assets/images/index/idea.jpg" class="idea-img">
					</picture>
				</a>
			</div>
		</div>

	</div>
</section>


<!-- 認識產品 -->
<section class="section-index" *ngIf="data">
	<div class="container-fluid">

		<div class="section-header d-block d-md-flex">
			<h2 class="title title-underline">認識產品</h2>
			<h5 class="text">無添加的純粹美味</h5>
		</div>

	</div>

	<owl-carousel-o [options]="product_option" class="product-carousel">
    <ng-template carouselSlide>
    	<div class="item">
    		<a [routerLink]="['/content/introduction/info/1']">
	    		<div class="image">
	    			<img src="/assets/images/index/product_01.jpg?v=20220802">
	    		</div>
	    		<div class="body">
	    			<h5 class="title">嚴選優格</h5>
	    			<p class="text">純鮮奶發酵 綿密細滑</p>
	    		</div>
	    	</a>
    	</div>
    </ng-template>
    <ng-template carouselSlide>
    	<div class="item">
    		<a [routerLink]="['/content/page/preserves']">
	    		<div class="image">
	    			<img src="/assets/images/index/product_02.jpg?v=20220802">
	    		</div>
	    		<div class="body">
	    			<h5 class="title">嚴選果漿</h5>
	    			<p class="text">友善植人 選當季食在地</p>
	    		</div>
	    	</a>
    	</div>
    </ng-template>
    <ng-template carouselSlide>
    	<div class="item">
    		<a [routerLink]="['/content/page/granola']">
	    		<div class="image">
	    			<img src="/assets/images/index/product_03.jpg?v=20220802">
	    		</div>
	    		<div class="body">
	    			<h5 class="title">嚴選燕麥</h5>
	    			<p class="text">綜合穀物 新鮮烘烤</p>
	    		</div>
	    	</a>
    	</div>
    </ng-template>
    <ng-template carouselSlide>
    	<div class="item">
    		<a [routerLink]="['/content/introduction/info/2']">
	    		<div class="image">
	    			<img src="/assets/images/index/product_04.jpg?v=20220802">
	    		</div>
	    		<div class="body">
	    			<h5 class="title">嚴選保健</h5>
	    			<p class="text">隨時隨地補充好菌</p>
	    		</div>
	    	</a>
    	</div>
    </ng-template>  
    
    <ng-template carouselSlide [dataMerge]="4">
    	<div class="item">
    		<a [routerLink]="['/content/page/recycle']">
	    		<div class="image recycle">
	    			<img src="/assets/images/index/product_05.jpg">
	    		</div>
	    		<div class="body text-center">
	    			<h5 class="title">友善大地從回收做起</h5>
	    			<p class="text">將空瓶帶到馬修嚴選門市/專櫃回收</p>
	    			<a [routerLink]="['/content/page/recycle']" class="btn btn-outline-dark mt-2">
							<span>暸解更多</span><nb-icon icon="chevron-right"></nb-icon>
						</a>
	    		</div>
	    	</a>
    	</div>
    </ng-template> 
	</owl-carousel-o>

</section>


<!-- 產地植人 -->
<section class="section-index" *ngIf="data">
	<div class="container-fluid">

		<div class="section-header d-block d-md-flex">
			<h2 class="title title-underline">產地植人</h2>
			<h5 class="text">你與產地，只有一個馬修嚴選的距離</h5>
		</div>

		<ng-container *ngIf="data.reviews.items.length>(mobile?1:3)">
			<owl-carousel-o [options]="origin_option" class="origin-carousel">
			    <ng-template carouselSlide *ngFor="let item of data.reviews.items">
			    	<div class="card-overlay">
			    		<a [routerLink]="['/content/review/info', item.id]">
			    			<div class="image">
			    				<img [src]="filepath+fileService.getThumbnail(item.home_image,'s')">
			    				<div class="overlay">
			    					<h5 class="title">{{ item.title }}</h5>
			    					<p class="text">{{ item.writer_job_title }}</p>
			    				</div>
			    			</div>
			    		</a>
			    	</div>
			    </ng-template>  
		  </owl-carousel-o>
		</ng-container>
		<ng-container *ngIf="data.reviews.items.length<=(mobile?1:3)">
			<div class="origin-carousel">
				<div class="row">
					<div class="col-md-4" *ngFor="let item of data.reviews.items">
						<div class="card-overlay">
							<a [routerLink]="['/content/review/info', item.id]">
								<div class="image">
									<img [src]="filepath+fileService.getThumbnail(item.home_image,'s')">
									<div class="overlay">
										<h5 class="title">{{ item.title }}</h5>
										<p class="text">{{ item.writer_job_title }}</p>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</ng-container>

	</div>
</section>


<!-- 購買產品 -->
<section class="section-index">
	<div class="container-fluid">

		<div class="section-header d-block d-md-flex">
			<h2 class="title title-underline">購買產品</h2>
			<h5 class="text">馬修嚴選在哪裡?</h5>
		</div>

	</div>

	<owl-carousel-o [options]="buy_option" class="buy-carousel">
    <ng-template carouselSlide>
    	<div class="item">
    		<a [routerLink]="['/products/home']">
    			<img src="/assets/images/index/buy_01.svg" class="img">
    			<p class="text">網路訂購</p>
    		</a>
    	</div>
    </ng-template>
    <ng-template carouselSlide>
    	<div class="item">
    		<a href="https://page.line.me/matthewschoice" target="_blank">
    			<img src="/assets/images/index/buy_02.svg" class="img">
    			<p class="text">真人客服</p>
    		</a>
    	</div>
    </ng-template>
    <ng-template carouselSlide>
    	<div class="item">
    		<a [routerLink]="['/store']">
    			<img src="/assets/images/index/buy_05.svg" class="img">
    			<p class="text">預約試吃</p>
    		</a>
    	</div>
    </ng-template>
    <ng-template carouselSlide>
    	<div class="item">
    		<a [routerLink]="['/store']" fragment="store">
    			<img src="/assets/images/index/buy_03.svg" class="img">
    			<p class="text">門市資訊</p>
    		</a>
    	</div>
    </ng-template>
    <ng-template carouselSlide>
    	<div class="item">
    		<a [routerLink]="['/content/page/sales-channel']">
    			<img src="/assets/images/index/buy_04.svg" class="img">
    			<p class="text">通路資訊</p>
    		</a>
    	</div>
    </ng-template>
	</owl-carousel-o>

</section>


<!-- 顧客見證 -->
<section class="section-index" id="ig" *ngIf="ig">
	<div class="container-fluid">

		<div class="section-header d-block d-md-flex">
			<h2 class="title title-underline">顧客見證</h2>
			<h5 class="text">吃一口就回不去了!</h5>
		</div>

	</div>

	<owl-carousel-o [options]="gallery_option" class="gallery-carousel">
    <ng-template carouselSlide *ngIf="ig.length>0">
    	<div class="gallery">
    		<ng-container *ngFor="let ig_index of ig_1">
	    		<div class="item" *ngIf="ig[ig_index]" (click)="openIGDialog(ig_index)">
	    			<img [src]="filepath+fileService.getThumbnail(ig[ig_index].image,'s')" alt="">
	    		</div>
	    	</ng-container>
    	</div>
    </ng-template>
    <ng-template carouselSlide *ngIf="ig.length>5">
    	<div class="gallery">
    		<ng-container *ngFor="let ig_index of ig_2">
	    		<div class="item" *ngIf="ig[ig_index]" (click)="openIGDialog(ig_index)">
	    			<img [src]="filepath+fileService.getThumbnail(ig[ig_index].image,'s')" alt="">
	    		</div>
	    	</ng-container>
    	</div>
    </ng-template> 
	</owl-carousel-o>

</section>







<!-- 單一圖片/影片 模板 -->
<ng-template #singleBanner let-input>

	<ng-container *ngIf="input.file_type=='image'">
		<a *ngIf="input.link_type=='in'" [routerLink]="[input.url]"><img [src]="filepath+fileService.getThumbnail(input.filepath,'s')" class="img-fluid"></a>
		<a *ngIf="input.link_type=='out'" href="{{ input.url }}" target="_blank"><img [src]="filepath+fileService.getThumbnail(input.filepath,'s')" class="img-fluid"></a>
		<img *ngIf="input.link_type=='none'" [src]="filepath+fileService.getThumbnail(input.filepath,'s')" class="img-fluid">
	</ng-container>

	<ng-container *ngIf="input.file_type=='video'">
		<a *ngIf="input.link_type=='in'" [routerLink]="[input.url]"><video controls [src]="filepath+input.filepath"></video></a>
		<a *ngIf="input.link_type=='out'" href="{{ input.url }}" target="_blank"><video controls [src]="filepath+input.filepath"></video></a>
		<video *ngIf="input.link_type=='none'" controls [src]="filepath+input.filepath"></video>
	</ng-container>
</ng-template>