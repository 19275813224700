import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { catchError, map, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class CartGuard implements CanActivate, CanActivateChild {

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object, 
        private tokenService:TokenService, 
        private router:Router, 
        private logger:NGXLogger
    ) {}
    
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(next, state);
      }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(next, state);
    }


    //進行檢查
    check(next, state){

        //權限代碼
        this.logger.debug('cart guard');
        


        // BROWSER
        //if (isPlatformBrowser(this.platformId)) {

            return this.tokenService.infoState('member').pipe(


                map(code => {
                

                    switch (code) {

                        case "SUCCESS":
                            localStorage.setItem('user_role', 'member');
                            return true;

                           //訪客是否允許
                        default:
                            localStorage.setItem('user_role', 'guest');
                            this.router.navigate(['/auth/login'], { queryParams: {url: state.url} });
                            return false;
                    }
                      
                  })

              );
        //}
    
    }
  
}
