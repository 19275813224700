import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayNumberWithCommas'
})
export class DisplayNumberWithCommasPipe implements PipeTransform {

  transform(value: number | string, ...args: unknown[]): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
