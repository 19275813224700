import { Component, OnInit, Inject, PLATFORM_ID, Renderer2, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { HttpService } from './services/http.service';
import { LayoutService } from './services/layout.service';
import { MetaService } from './services/meta.service';
import { EventTrackingService } from './services/event-tracking.service';

declare let gtag: Function;
declare let ga: Function;
declare let google: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})


export class AppComponent {

	title = 'shopadmin';
	

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private renderer: Renderer2,
		private router: Router,
		private route: ActivatedRoute,
		private httpService: HttpService,
		private layoutService: LayoutService,
		private metaService: MetaService,
		private eventTrackingService: EventTrackingService
	) {}

	ngOnInit() {
		//設定網站頁籤title 與 初始seo
		this.metaService.setMetaByDefault();
		this.eventTrackingService.setupEventTracking(this.renderer);

		//設定網頁favicon
		if(isPlatformBrowser(this.platformId)){
			let favIcon: HTMLLinkElement = document.querySelector('#appTitleIcon');
			favIcon.href = environment.favIcon;
		}
		

		if(isPlatformBrowser(this.platformId) && environment.GA.page_view){

			//強制轉到到外部瀏覽器
			this.openExternalBrowser();

			//ga page_view
			this.router.events
			.pipe(distinctUntilChanged((previous: any, current: any) => {
				if (current instanceof NavigationEnd) {
					return previous.url === current.url;
				}
				return true;
			}))
			.subscribe((x: any) => { 
				ga('send', 'pageview', x.url);
				//gtag('event', 'page_view', { 'page_path': x.url }); 
			});

			


			//擷取網址苗點內容
			/*
			this.route.fragment.subscribe((fragment: string) => {
        		console.warn("My hash fragment is here => ", fragment)
    		})*/
		}
	}


	//關閉網頁初始loading動畫
	ngAfterViewInit(): void {

		if (isPlatformBrowser(this.platformId)) {
			let loader = this.renderer.selectRootElement('.app-loading');
			if (loader.style.display != "none") loader.style.display = "none"; //hide loader
			console.log("test view init")

			// 網址帶有hash時 跳至該id元素位置
			window.addEventListener('load', function(){
				setTimeout(function(){
					let hash = window.location.hash;
					let id = hash.split("#")[1];
					let anchor = document.getElementById(id)
					if(hash && anchor){
						let top = anchor.offsetTop;
						window.scrollTo({
							top: top,
							left: 0,
						});
					}
				}, 1000)
			})
		}
		
	}





	//強制轉到到外部瀏覽器
	openExternalBrowser(){
		var u = navigator.userAgent,
		ua = navigator.userAgent.toLowerCase(),
		isLineApp = u.indexOf("Line") > -1, // Line 內建瀏覽器
		isFbApp = u.indexOf("FBAN") > -1 // FB App 內建瀏覽器

		// 如果是 Line 內建瀏覽器
		if (isLineApp) {
			this.lineRedirect();
		}

		// 如果是 FB App 內建瀏覽器
		/*
		if (isFbApp) {
			this.fbRedirect(u);
		}*/
	}


	//line 瀏覽器轉導
	lineRedirect(){
		// 沒有包含參數就重新導向
		// 網址沒有參數 ? 時, 直接加參數
		if (location.href.indexOf("?") < 0) {
			location.href += "?openExternalBrowser=1";
		} else {
		// 網址有參數 ? 時, 用 & 加參數
			location.href += "&openExternalBrowser=1";
		}
	}

	//fb 瀏覽器轉導
	fbRedirect(u){
		// 重新導向用chrome開啟網頁
		if(u.indexOf('iPhone')){
			location.href = 'googlechrome://' + location.hostname + location.pathname + location.search;
		}else if(u.indexOf('Android')){
			location.href = 'googlechrome://navigate?' + location.hostname + location.pathname + location.search;
		}
	}

	
}
