import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {

	shop = {
		id:localStorage.getItem('shop_id'),
		name:localStorage.getItem('shop_name')
	};

	defaultHidden = true;

	menus: NbMenuItem[] = [
    	{
    	  	title: '商城系統',
            icon: 'shopping-cart',
            data : 'shop-system',
    	  	expanded: true,
            hidden: this.defaultHidden,
    	  	children: [
		        {
		          	title: '選擇商店',
                    icon: 'home-outline',
                    data: 'shop-shop',
		          	link: '/shop/select',
		          	hidden: this.defaultHidden,
		        },
		        {
		         	title: '商品類別管理',
                    icon: 'grid-outline',
                    data: 'shop-category',
                    link: '/shop/category',
                    hidden: this.defaultHidden,
		       	},
		       	{
		         	title: '商品管理',
                    data: 'shop-product',
                    link: '/shop/product',
                    icon: 'shopping-bag-outline',
		         	hidden: this.defaultHidden,
		      	},
		      	{
		         	title: '庫存與價格管理',
                    data: 'shop-sku',
                    link: '/shop/sku',
                    icon: 'pricetags-outline',
		         	hidden: this.defaultHidden,
		      	},
    	  	],
    	},
        {
            title: '內容管理系統',
            icon: 'book',
            data : 'content-system',
            expanded: true,
            hidden: this.defaultHidden,
            children: [
                {
                    title: 'Banner管理',
                    icon: 'image-outline',
                    data: 'content-banner',
                    link: '/content/banner',
                    hidden: this.defaultHidden,
                },
                {
                    title: '最新消息',
                    data: 'content-news',
                    link: '/content/news',
                    icon: 'message-square-outline',
                    hidden: this.defaultHidden,
                },
                {
                    title: '最新消息類別管理',
                    icon: 'grid-outline',
                    data: 'content-news-category',
                    link: '/content/news/category',
                    hidden: this.defaultHidden,
                },
                {
                    title: '文章管理',
                    icon: 'message-square-outline',
                    data: 'content-article',
                    link: '/content/article',
                    hidden: this.defaultHidden,
                },
                {
                    title: '文章類別管理',
                    icon: 'grid-outline',
                    data: 'content-article-category',
                    link: '/content/article/category',
                    hidden: this.defaultHidden,
                },
                {
                    title: '客製頁面',
                    icon: 'browser-outline',
                    data: 'content-page',
                    link: '/content/page',
                    hidden: this.defaultHidden,
                },
                {
                    title: '頁尾管理',
                    icon: 'layout-outline',
                    hidden: this.defaultHidden,
                },
            ],
        },
    	{
    	  	title: '會員系統',
            icon: 'people',
    	  	hidden: this.defaultHidden,
    	},
        {
            title: '管理員中心',
            icon: 'person',
            data : 'administator-system',
            expanded: true,
            hidden: this.defaultHidden,
            children: [
                {
                    title: '管理員列表',
                    icon: 'list',
                    hidden: this.defaultHidden,
                },
                {
                    title: '權限管理',
                    icon: 'person-delete-outline',
                    hidden: this.defaultHidden,
                },
                {
                    title: '操作紀錄',
                    icon: 'activity-outline',
                    hidden: this.defaultHidden,
                },
            ]
        },
  	];

  	constructor(
  		private httpService: HttpService
  	) { }

  	ngOnInit(): void {
  		this.showMenu();
  	}


  	//顯示menu (依照權限)
  	showMenu() {
  		let url=`${environment.APIUrl}/api/lab-shop/admin/permissions`;

  		this.httpService.getHttp(url).subscribe(
  			res => {
  				//如果localstorage沒有存目前管理的shop id, 則採用預設
  				if(!this.shop.id){
  					this.setDefaultShop(res.shop.id, res.shop.name);
  				}

  				

	  			//設定那些menu要顯示
	  			res.items.forEach(item => {
	  					
	  				this.menus.map(menu =>{
	  					//顯示
	  					if(res.super_admin==1 || menu.data==item){
	  						menu.hidden = false;
	  					}

	  					//檢查children
	  					if(menu.children){
		  					menu.children.map(child=>{
		  						if(res.super_admin==1 || child.data==item){
		  							child.hidden = false;
		  						}
		  					});
		  				}
	  				}); //menus map
	  			}); //items foreach
	  			


  				//menu店家名稱顯示
  				this.menus[0].title = `商城系統[${this.shop.name}]`;

  				//console.log(this.menus);
  			}
  		);
  	}


  	//取得目前管理的店家ID與名稱
  	setDefaultShop(shop_id, shop_name) {
  		
  		if(!this.shop.id){
  			localStorage.setItem('shop_id', shop_id);
  			localStorage.setItem('shop_name', shop_name);
  			this.shop.id = localStorage.getItem('shop_id');
  			this.shop.name = localStorage.getItem('shop_name');
  		}



  	}

}
