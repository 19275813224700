export const Menu:any =  [

	//會員資訊
	{
		code: 'menu.member',
		link: null,
		children: [
			/*
			{
				code: 'menu.memberRegister',
				link: ['/auth/register'],
			},
			{
				code: 'menu.memberLogin',
				link: ['/auth/login'],
			},*/
			{
				code: 'menu.memberInfo',
				link: ['/member/info'],
			},
			{
				code: 'menu.orderDefault',
				link: ['/member/order/records'],
			},
			{
				code: 'menu.orderSplit',
				link: ['/member/order/store'],
			},
			{
				code: 'menu.orderSubscription',
				link: ['/member/order/subscription'],
			},
			{
				code: 'menu.memberAccount',
				link: ['/member/account'],
			},
			{
				code: 'menu.memberFavorite',
				link: ['/member/favorite'],
			},
		]
	},



	//最新消息
	{
		code: 'menu.news',
		link: null,
		children: [
			{
				code: 'menu.newsList',
				link: ['/content/news'],
			},
			{
				code: 'menu.newsActivity',
				link: ['/content/activity'],
			},
			{
				code: 'menu.newsMedia',
				link: ['/content/media'],
			}
		]
	},


	//品牌理念
	{
		code: 'menu.brand',
		link: null,
		children: [
			{
				code: 'menu.brandIdea',
				link: ['/content/page/brand'],
			},
			{
				code: 'menu.brandHistory',
				link: ['/content/page/brand-history'],
			},
			{
				code: 'menu.pixnetDiary',
				link: ['/content/diary'],
			},
			{
				code: 'menu.RecyclePage',
				link: ['/content/page/recycle'],
			}
		]
	},


	//認識產品
	{
		code: 'menu.introduction',
		link: null,
		children: [
			{
				code: 'menu.introductionYogurt',
				link: ['/content/introduction/info/1'],
			},
			{
				code: 'menu.introductionHealth',
				link: ['/content/introduction/info/2'],
			},
			{
				code: 'menu.introductionOatmeal',
				link: ['/content/page/granola'],
			},
			{
				code: 'menu.introductionJam',
				link: ['/content/page/preserves'],
			}
		]
	},


	//生活提案
	{
		code: 'menu.blog',
		link: null,
		children: [
			{
				code: 'menu.recipe',
				link: ['/content/recipe'],
			}
		]
	},


	//產地直人
	{
		code: 'menu.review',
		link: null,
		children: [
			{
				code: 'menu.reviewList',
				link: ['/content/review'],
			}
		]
	},


	//網路訂購
	{
		code: 'menu.shop',
		link: null,
		children: [
			
		]
	},


	//實體通路
	{
		code: 'menu.store',
		link: null,
		children: [
			{
				code: 'menu.storeList',
				link: ['/store'],
			},
			{
				code: 'menu.channelList',
				link: ['/content/page/sales-channel'],
			}
		]
	},


	//常見問題
	{
		code: 'menu.qa',
		link: null,
		children: [
			{
				code: 'menu.qaInfo',
				link: ['/content/page/qa'],
			},
			{
				code: 'menu.purchaseInfo',
				link: ['/content/page/purchase-info'],
			},
			// {
			// 	code: 'menu.line',
			// 	link: null,
			// },
		]
	},
]