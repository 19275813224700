<!-- 購物車無商品 -->
<section class="section-empty py-0" *ngIf="cart_empty">
  <!-- 操作按鈕 -->
  <div class="sidebar-close">
    <button type="button" class="btn btn-sm btn-icon px-0" (click)="close()">
      <nb-icon icon="close"></nb-icon>
    </button>
  </div>

  <div class="container">
    <img src="/assets/images/icon/cart.svg" class="icon" />
    <p>購物車目前無商品</p>
  </div>
</section>

<!-- 購物車資訊 -->
<section *ngIf="cart">
  <!-- 操作按鈕 -->
  <div class="sidebar-close">
    <button type="button" class="btn btn-sm btn-icon px-0" (click)="close()">
      <nb-icon icon="close"></nb-icon>
    </button>
  </div>

  <!-- 購物車數量 -->
  <div class="cart-title">
    購物車數量總計：<span>{{ cart.sum_quantity }}</span>
  </div>

  <!-- 訂單商品 -->
  <!-- 符合特定商品活動的商品清單 -->

  <!-- 主商品列表 -->
  <ul class="table-resp table-resp-cart">
    <li class="row detail" *ngFor="let product of sku_items">
      <div
        *ngTemplateOutlet="singleProduct; context: { $implicit: product }"
      ></div>
    </li>
  </ul>

  <!-- 加購品列表 -->
  <div *ngIf="usedGiftsAndAddons.length > 0">
    <div class="px-3 mb-0">已享用之優惠</div>
    <ul class="table-resp table-resp-cart border-top-0">
      <li class="row detail" *ngFor="let giftAndAddon of usedGiftsAndAddons">
        <!-- 活動名稱 -->
        <div class="col-12 px-2 mb-1">
          <div>
            <span class="text-secondary fw-normal">
              <ng-container *ngIf="giftAndAddon.type === 'addon'"
                >加購優惠 -</ng-container
              >
              <ng-container *ngIf="giftAndAddon.type === 'gift'"
                >贈品 -</ng-container
              >{{ giftAndAddon.eventName }}
            </span>
          </div>
        </div>

        <!-- 主商品 -->
        <div class="col-12">
          <!-- <span class="label">商品</span> -->
          <div>
            <a class="image">
              <img
                [src]="
                  filepath +
                  fileService.getThumbnail(giftAndAddon.product.image, 'xs')
                "
              />
            </a>
            <div class="info">
              <!-- 商品編號 -->
              <!-- <span class="text-muted">{{ 'cart_product.model' | translate }}：{{ product.model }}</span> -->

              <!-- 商品連結 -->
              <a>{{ giftAndAddon.product.name }}</a>
            </div>
          </div>
        </div>

        <!-- 加購價 -->
        <div class="col-12" *ngIf="giftAndAddon.type === 'addon'">
          <span class="label">加購價</span>
          <div>
            <!-- 顯示定價與結帳架 -->
            <span class="discount">
              <!-- {{ 'product.price.checkout' | translate }}： -->
              <small>$</small>{{ giftAndAddon.sub_total }}
            </span>
          </div>
        </div>

        <!-- 贈品 -->
        <div class="col-12" *ngIf="giftAndAddon.type === 'gift'">
          <div>
            <span class="label">贈品</span>
          </div>
        </div>

        <!-- 數量 -->
        <div class="col-12">
          <span class="label">數量</span>
          <div>
            {{ giftAndAddon.quantity }}
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="border-bottom"></div>

  <!--- 免運券資訊 -->
  <div class="p-3" *ngIf="shipping_coupon_feedback">
    <span
      class="text-secondary fw-normal text-sm"
      *ngIf="shipping_coupon_feedback.next_level_count == 0"
    >
      未達免運門檻：您還差{{
        shipping_coupon_feedback.next_level_amount
      }}元即可享免運
    </span>
    <span
      class="text-secondary fw-normal text-sm"
      *ngIf="shipping_coupon_feedback.next_level_count > 0"
    >
      已達免運門檻：您還差{{
        shipping_coupon_feedback.next_level_amount
      }}元可多獲得{{ shipping_coupon_feedback.next_level_count }}張免運券
    </span>
  </div>

  <!--- 訂單明細 -->
  <div
    *ngTemplateOutlet="orderTotal; context: { $implicit: order_summary }"
  ></div>

  <!-- 操作按鈕 -->
  <div class="text-center mt-4 pt-2">
    <a
      class="btn btn-outline-dark"
      [routerLink]="['/cart/order']"
      (click)="close()"
    >
      <span>前往結帳</span><nb-icon icon="chevron-right"></nb-icon>
    </a>
  </div>
</section>

<!--
	單一主商品資訊
-->
<ng-template #singleProduct let-product let-discount_event="discount_event">
  <div class="col-12">
    <!-- <span class="label">商品</span> -->
    <div>
      <a
        class="image"
        [routerLink]="[product_info_link, product.model]"
        (click)="close()"
      >
        <img [src]="filepath + fileService.getThumbnail(product.image, 'xs')" />
      </a>
      <div class="info">
        <!-- 商品編號 -->
        <!-- <span class="text-muted">{{ 'cart_product.model' | translate }}：{{ product.model }}</span> -->

        <!-- 商品連結 -->
        <a
          *ngIf="product.type != 'combo'"
          [routerLink]="[product_info_link, product.model]"
          (click)="close()"
        >
          <p>{{ product.name }}</p>
          <app-tag *ngIf="product.category_name === '點數換購'">
            {{ product.category_name }} ({{ product.d_price }}點)
          </app-tag>
        </a>
      </div>
    </div>
  </div>

  <!-- 商品單價 -->
  <div class="col-12">
    <span class="label">單價</span>
    <div>
      <!-- 顯示定價與結帳架 -->
      <span class="discount">
        <span
          *ngTemplateOutlet="
            price;
            context: { $implicit: product.calculated_price }
          "
        ></span>
      </span>
    </div>
  </div>

  <!-- 數量 -->
  <div class="col-12">
    <span class="label">數量</span>
    <div>
      {{ product.amount }}
    </div>
  </div>

  <!-- 符合優惠活動 -->
  <div class="col-12" *ngFor="let attendEvent of product.attendEvents">
    <div class="flex-column">
      <span class="label text-secondary">符合優惠活動</span>
      <span class="text-secondary fw-normal text-xs">{{
        attendEvent.name
      }}</span>
    </div>
  </div>
</ng-template>

<!--- 金錢顯示格式 -->
<ng-template #price let-input>
  <span *ngIf="input < 0"
    ><small>-$</small>{{ -input | currency : "TWD" : "" : "1.0-0" }}</span
  >
  <span *ngIf="input >= 0"
    ><small>$</small>{{ input | currency : "TWD" : "" : "1.0-0" }}</span
  >
</ng-template>

<!-- 總價資訊 -->
<ng-template #orderTotal let-order_summary>
  <div class="cart-title mt-2">訂單資訊</div>

  <input type="checkbox" class="control d-none" id="toggler" />
  <div class="cart-list-collapse">
    <div class="cart-list">
      <!-- 紅利點數使用明細 -->
      <!-- <div class="cart-list-item" *ngIf="order_dollbao_summary.dollbao_product_total!=0"> -->
      <!-- <span>使用紅利點數數量(兌換商品)</span>
				<span>{{ order_dollbao_summary.dollbao_product_total }}</span> -->
      <!-- </div> -->

      <!-- 訂單金額明細 （台幣） -->
      <div class="cart-list-item">
        <span>訂單商品小計</span>
        <span
          *ngTemplateOutlet="
            price;
            context: { $implicit: order_summary.product_total }
          "
        ></span>
      </div>
      <div class="cart-list-item" *ngIf="order_summary.discount_event != 0">
        <span>行銷活動優惠</span>
        <span
          *ngTemplateOutlet="
            price;
            context: { $implicit: order_summary.discount_event }
          "
        ></span>
      </div>
      <div class="cart-list-item" *ngIf="order_summary.addonsTotal != 0">
        <span>加購價</span>
        <span
          *ngTemplateOutlet="
            price;
            context: { $implicit: order_summary.addonsTotal }
          "
        ></span>
      </div>
      <div
        class="cart-list-item"
        *ngIf="
          order_summary.member_discount_amount &&
          order_summary.member_discount_amount != 0
        "
      >
        <span>會員等級優惠</span>
        <span
          *ngTemplateOutlet="
            price;
            context: { $implicit: order_summary.member_discount_amount }
          "
        ></span>
      </div>

      <!-- 運費 -->
      <div class="cart-list-item" *ngIf="shipping_fee != 0">
        <span>運費</span>
        <span
          *ngTemplateOutlet="price; context: { $implicit: shipping_fee }"
        ></span>
      </div>
    </div>
  </div>

  <div class="cart-total">
    <span>訂單總計</span>
    <span class="price"
      ><b
        ><span
          *ngTemplateOutlet="price; context: { $implicit: currentCheckTotal() }"
        ></span></b
    ></span>
    <label for="toggler" class="toggler">
      <nb-icon icon="arrow-ios-downward"></nb-icon>
    </label>
  </div>
</ng-template>
