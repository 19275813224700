import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss']
})
export class LangComponent implements OnInit {

    toggle = false;
    currentLang;

 
  	constructor(
  		public languageService: LanguageService
  	) { }

  	ngOnInit(): void {
        this.currentLang = this.languageService.translate.currentLang;
  	}

  	
  	

  	useLanguage(language: string) {
    	this.languageService.setLang(language);
      this.currentLang = language;
      this.toggle = false;
  	}


    dropdownToggle(){
      this.toggle = !this.toggle;
    }

}
