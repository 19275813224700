import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';
import { Location } from '@angular/common'; //接收route para
import { catchError, map, tap } from 'rxjs/operators';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
//import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material'; //popup dialog
//import { LoginComponent } from '../auth/login/login.component';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class LoginGuard implements CanActivate {

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object, 
		private tokenService:TokenService, 
		private router:Router, 
		//private dialog:MatDialog
	) {}

	canActivate(
    	next: ActivatedRouteSnapshot,
    	state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean 
    {
    	return this.check(next, state);
    }


    canActivateChild(
    	next: ActivatedRouteSnapshot,
    	state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean 
    {
    	return this.check(next, state);
    }


    //進行權限檢查
    check(next, state){


		// BROWSER
   		//if (isPlatformBrowser(this.platformId)) {

			return this.tokenService.infoState('member').pipe(


				map(code => {

					switch (code) {

						case "SUCCESS":
							return true;

						default:
							//登入後回去上一頁
							//console.warn(state.url);
							this.router.navigate(['/auth/login'], { queryParams: {url: state.url} });
				       		return false;
					}
		      	})

	      	);
		//}
	
  	}
}
