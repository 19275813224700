import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GtagService {

  constructor(
    @Inject(DOCUMENT) private _document: Document,
		@Inject(PLATFORM_ID) private platformId: Object
  ) { }

  setupScript(renderer2: Renderer2) {
    let script = renderer2.createElement('script');
    script.setAttribute('asnyc', true);
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GTag.trackingID}`;
    renderer2.appendChild(this._document.head, script);
    
    let script2 = renderer2.createElement('script');
    script2.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.GTag.trackingID}');
      gtag('event', 'conversion', {'send_to': '${environment.GTag.trackingID}/${environment.GTag.actions.pageView}'})
    `;
    renderer2.appendChild(this._document.head, script2);
  }

  addToCart(skus) {
		if(isPlatformBrowser(this.platformId)){
      try {
        skus.forEach(sku => {
          gtag('event', 'conversion', {
            'send_to': `${environment.GTag.trackingID}/${environment.GTag.actions.addToCart}`,
            'value': sku.member_price,
            'currency': 'TWD',
            'event_callback': () => {},
          });
        })
      } catch (error) {
        console.error('GtagService#addToCart', skus, error)
      }
    }
  }

  purchase(order_number, order_total) {
		if(isPlatformBrowser(this.platformId)){
      gtag('event', 'conversion', {
        'send_to': `${environment.GTag.trackingID}/${environment.GTag.actions.purchase}`,
          'value': order_total,
          'currency': 'TWD',
          'transaction_id': order_number,
      });
    }
  }
  
}
