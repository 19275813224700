import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpService } from '../../services/http.service';
import { FileService } from '../../services/file.service';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

	@Input() type;
	@Input() list;
	@Input() customClass;

	filepath = environment.FileUrl;
	defaultImage = environment.lazyLoad_defaultImg;

	activeSlides;
	startPosition = 0;
  	mobile;



	@Input() customOptions: OwlOptions = {
      animateOut: 'fadeOut',
	    loop: true,
      autoplay: true,
      autoplayTimeout: 8000,
      lazyLoad: true,
      dots: true,
      navText: ['<img src="/assets/images/icon/chevron-left.svg">', '<img src="/assets/images/icon/chevron-right.svg">'],
      responsive: {
        0: {
          items: 1
        }
      },
      nav: false,
	}

  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		private httpService: HttpService,
      public fileService: FileService
  	) { }

  	ngOnInit(): void {
  		//this.getList(this.type);
  		if(isPlatformBrowser(this.platformId)){
		    if(window.screen.width < 992){
		        this.mobile = true
		    }
		}
  	}


  	getList(type) {
  		let url = `${environment.APIUrl}/api/lab-web-content/front/banners?type=${type}`;
  		this.httpService.getHttp(url).subscribe(
  			res => {
  				this.list = res.items;
  			}
  		);
  	}

    getPassedData(data) {
      this.activeSlides = data;
      this.startPosition = data.startPosition;
      // console.log(this.activeSlides);
    }
}
