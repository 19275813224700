import { Injectable, Inject, PLATFORM_ID, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

declare let gtag: Function;
declare let ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GaService {

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private translate: TranslateService
	) { }

	// 查看商品清單時
	gaViewItemList(lists){
		if(isPlatformBrowser(this.platformId)){

			let products = [];

			if(lists){
				lists.forEach(list => {
					list.products.items.forEach(item => {
						let product = {
							item_id: item.model,
							item_name: item.name,
							item_brand: "馬修嚴選",
							item_category: item.category.name,
							item_list_id: item.category.id,
							item_list_name: item.category.name,
							price: item.price,
							quantity: 1
						}
						products.push(product)
					})
				})
			}

			// GA4 事件: view_item_list
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
				event: "td_view_item_list",
				ga4_event_name: "view_item_list",
				ecommerce: {
					items: products
				}
			});
			
		}
	}

	// 從清單中選取商品
	gaSelectItem(product, selectNum){
		if(isPlatformBrowser(this.platformId)){

			// GA4 事件: select_item
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
				event: "td_select_item",
				ga4_event_name: "select_item",
				ecommerce: {
					items: [{
						item_id: product.model,
						item_name: product.name,
						item_brand: "馬修嚴選",
						item_category: product.category.name,
						item_list_id: product.category.id,
						item_list_name: product.category.name,
						price: product.price,
						quantity: selectNum
					}]
				}
			});

		}
	}

	// 查看商品頁
	gaViewItem(product, brand_name){
		if(isPlatformBrowser(this.platformId)){

			// GA4 事件: view_item
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
			event: "td_view_item",
			ga4_event_name: "view_item",
				ecommerce: {
					items: [{
						item_id: product.model,
						item_name: product.name,
						item_brand: "馬修嚴選",
						item_category: product.category.name,
						item_list_id: product.category.id,
						item_list_name: product.category.name,
						price: product.price,
						quantity: 1
					}]
				}
			});

		}
	}

	// 將商品加入願望清單
	gaAddToWishlist(product, selectNum){
		if(isPlatformBrowser(this.platformId)){

			// GA4 事件: add_to_wishlist
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
				event: "td_add_to_wishlist",
				ga4_event_name: "add_to_wishlist",
				ecommerce: {
					items: [{
						item_id: product.model,
						item_name: product.name,
						item_brand: "馬修嚴選",
						item_category: product.category.name,
						item_list_id: product.category.id,
						item_list_name: product.category.name,
						price: product.price,
						quantity: selectNum
					}]
				}
			});

		}
	}

	// 將商品加入購物車
	gaAddToCart(skus){
		if(isPlatformBrowser(this.platformId)){

			skus.forEach(sku => {
				// GA4 事件: add_to_cart
				(<any>window).dataLayer.push({ ecommerce: null });
				(<any>window).dataLayer.push({
					event: "td_add_to_cart",
					ga4_event_name: "add_to_cart",
					ecommerce: {
						items: [{
							item_id: sku.model,
							item_name: sku.product_name,
							item_brand: "馬修嚴選",
							item_category: sku.category.name,
							item_list_id: sku.category.id,
							item_list_name: sku.category.name,
							price: sku.member_price,
							quantity: sku.buy_quantity
						}]
					}
				});
			})

		}
	}

	// 查看購物車
	gaViewCart(skus){
		if(isPlatformBrowser(this.platformId)){

			let products = [];

			if(skus){
				skus.forEach(sku => {
					let product = {
						item_id: sku.model,
						item_name: sku.name,
						item_brand: "馬修嚴選",
						item_category: sku.category_name,
						item_list_id: sku.category_id,
						item_list_name: sku.category_name,
						price: sku.price * sku.amount,
						quantity: sku.amount
					}
					products.push(product)
				})
			}

			// GA4 事件:view_cart
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
				event: "td_view_cart",
				ga4_event_name: "view_cart",
				ecommerce: {
					items: products 
				}
			});

		}
	}

	// 從購物車中移除商品
	gaRemoveFromCart(sku){
		if(isPlatformBrowser(this.platformId)){

			if(sku){
				// GA4 事件: remove_from_cart
				(<any>window).dataLayer.push({ ecommerce: null });
				(<any>window).dataLayer.push({
					event: "td_remove_from_cart",
					ga4_event_name: "remove_from_cart",
					ecommerce: {
						items: [{
							item_id: sku.model,
							item_name: sku.name,
							item_brand: "馬修嚴選",
							item_category: sku.category_name,
							item_list_id: sku.category_id,
							item_list_name: sku.category_name,
							price: sku.price * sku.amount,
							quantity: sku.amount
						}]
					}
				});
			}

		}
	}

	// 開始結帳
	gaBeginCheckout(skus){
		if(isPlatformBrowser(this.platformId)){

			let products = [];

			if(skus){
				skus.forEach(sku => {
					let product = {
						item_id: sku.model,
						item_name: sku.name,
						item_brand: "馬修嚴選",
						item_category: sku.category_name,
						item_list_id: sku.category_id,
						item_list_name: sku.category_name,
						price: sku.price * sku.amount,
						quantity: sku.amount
					}
					products.push(product)
				})
			}

			// GA4 事件: begin_checkout
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
				event: "td_begin_checkout",
				ga4_event_name: "begin_checkout",
				ecommerce: {
					items: products
				}
			});

		}
	};

	// 提交運送資訊
	gaAddShippingInfo(skus, if_split){
		if(isPlatformBrowser(this.platformId)){
			console.warn(skus)

			let products = [];

			if(skus){
				skus.forEach(sku => {
					let product = {
						item_id: sku.model,
						item_name: sku.name,
						item_brand: "馬修嚴選",
						item_category: sku.category_name,
						item_list_id: sku.category_id,
						item_list_name: sku.category_name,
						price: sku.price * sku.amount,
						quantity: sku.amount
					}
					products.push(product)
				})
			}

			// GA4 事件: add_shipping_info
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
				event: "td_add_shipping_info",
				ga4_event_name: "add_shipping_info",
				ecommerce: {
					shipping_tier: if_split == 0 ? '一次配送' : '分次配送',
					items: products
				}
			});

		}
	}

	// 提交付款資訊
	gaAddPaymentInfo(skus, payment_id){
		if(isPlatformBrowser(this.platformId)){

			let products = [];
			
			if(skus){
				skus.forEach(sku => {
					let product = {
						item_id: sku.model,
						item_name: sku.name,
						item_brand: "馬修嚴選",
						item_category: sku.category_name,
						item_list_id: sku.category_id,
						item_list_name: sku.category_name,
						price: sku.price * sku.amount,
						quantity: sku.amount
					}
					products.push(product)
				})
			}

			// GA4 事件: add_payment_info
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
				event: "td_add_payment_info",
				ga4_event_name: "add_payment_info",
				ecommerce: {
					payment_type: payment_id,
					items: products
				}
			});

		}
	}

	// 完成購買
	gaPurchase(order_number, order_total, shipping_fee, coupon, skus){
		if(isPlatformBrowser(this.platformId)){

			let products = [];
			
			if(skus){
				skus.forEach(sku => {
					let product = {
						item_id: sku.model,
						item_name: sku.product_name,
						item_brand: "馬修嚴選",
						item_category: sku.category.name,
						item_list_id: sku.category.id,
						item_list_name: sku.category.name,
						price: sku.price * sku.quantity,
						quantity: sku.quantity
					}
					products.push(product)
				})
			}

			// GA4 事件: purchase
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
				event: "td_purchase",
				ga4_event_name: "purchase",
				ecommerce: {
					transaction_id: order_number,
					value: order_total,
					shipping: shipping_fee,
					currency: "TWD",
					coupon: coupon,
					items: products
				}
			});

		}
	}

	// 收到退款
	gaRefund(order_number){
		if(isPlatformBrowser(this.platformId)){

			// GA4 事件: refund
			(<any>window).dataLayer.push({ ecommerce: null });
			(<any>window).dataLayer.push({
				event: "td_refund",
				ga4_event_name: "refund",
				ecommerce: {
					transaction_id: order_number
				}
			});

		}
	}

	// 登入事件
	gaTdLogin(){
		if(isPlatformBrowser(this.platformId)){
			// GA4 事件: td_login
			(<any>window).dataLayer = (<any>window).dataLayer || [];
			(<any>window).dataLayer.push({ "event": "td_login", "method": "general" });
		}
	}

	// 註冊事件
	gaTdSignUp(){
		if(isPlatformBrowser(this.platformId)){
			// GA4 事件: td_sign_up
			(<any>window).dataLayer = (<any>window).dataLayer || [];
			(<any>window).dataLayer.push({ "event": "td_sign_up", "method": "general" });
		}
	}


	//gtm建立訂單
	gtmCartFinish(order_number, order_total, shipping_fee, skus){
		if(isPlatformBrowser(this.platformId) && environment.GTM.cartFinish){

			//console.warn('gtm cart finish start');
			//console.warn(skus);

			(<any>window).dataLayer.push({ ecommerce: null });

			let products = [];

			//訂單商品
			skus.forEach(sku=>{

				//console.warn(sku);

				let brand_name = null;
    		if(sku.brand){
    			brand_name = sku.brand.name;
    		}

    		let category = null;
    		if(sku.category){
    			category = sku.category.url_code
    		}

    		let product = { 
    			id: sku.model, 
    			name: sku.name, 
    			category: category, 
    			brand: brand_name, 
    			variant: sku.options, 
    			price: sku.price, 
    			quantity: sku.quantity
				};

				products.push(product);

				//console.warn(products);

				if(sku.children){

					//console.warn(sku.children)
					sku.children.forEach(child=>{
						//console.warn(child)
						brand_name = null;
		    		if(child.brand){
		    			brand_name = child.brand.name;
		    		}

		    		category = null;
		    		if(child.category){
		    			category = child.category.url_code
		    		}
						products.push({ 
		    			id: child.model, 
		    			name: child.name, 
		    			category: category, 
		    			brand: brand_name, 
		    			variant: child.options, 
		    			price: child.price, 
		    			quantity: child.quantity
						});
					})
				}
			});// end foreach

			//console.warn('sku foreach end');

			(<any>window).dataLayer.push({
  			'event': 'checkout',
  			'ecommerce': {
  				'checkout': {
    				'actionField': {'step': 1, 'option': 'Visa'},
    				'products': products
   				}
   			}
 			});

 			//console.warn('gtm cart finish end');
 			//console.warn(products);
		}//end if
	}

}
