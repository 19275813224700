import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NbDialogRef } from '@nebular/theme';
import { isPlatformBrowser } from '@angular/common';

declare namespace instgrm {
  	namespace Embeds {
    	function process(): void;
  	}
}

@Component({
  selector: 'app-ig-dialog',
  templateUrl: './ig-dialog.component.html',
  styleUrls: ['./ig-dialog.component.scss']
})
export class IgDialogComponent implements OnInit {

	@Input() ig;
	@Input() index;

  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		public ref: NbDialogRef<IgDialogComponent>,
  	) { }

  	ngOnInit(): void {
  		
  	}

  	ngAfterViewInit(){
  		if(isPlatformBrowser(this.platformId)){
	     
	        if (window['instgrm']) {
	        	//console.warn('hihi')
      			instgrm.Embeds.process()
    		}
	        
	    }
  	}


    //close IG popup
    closeIGDialog() {
      // document.querySelector('html').classList.remove('overflow-hidden');
    }

}
