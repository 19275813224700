import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TreeService {


    nodes: (any)[] = [];

  	constructor() { }


  	//初始化tree nodes
  	resetNodes() {
  		this.nodes=[];
  	}


  	//為tree nodes child 新增ob object
  	addChild(nodes_array, node, ob, level, max_level) {

  		if(level>max_level){
  			return;
  		}

  		nodes_array.map((item, index, array)=>{

            if(item.id==node.parent_id){
            	ob.data.level = level;
            	item.data.hasChild = 1;
            	item.children.push(ob); 
            }else{
            	this.addChild(item.children, node, ob, (level+1), max_level);
            }
		});
  	}


  	//產生nebular tree format
  	nbTreeFormat(list, max_level=100, root_id=null) {

  		this.nodes=[];
  		let tree: (object)[] = [];
        list.forEach( node => {

        	//ob 為準備放入nodes裡面的物件(已格式化), node為原物件(未進行格式處理)
            let ob = {
            	'data': node,
            	'id': node.id,
            	'children':[],
                'expanded':true
        	};
        	//格外判斷資訊
        	ob.data['level'] = 1;
        	ob.data['hasChild'] = 0;
            
            if (node.parent_id == root_id ){
                this.nodes.push(ob);
            }else{
            	this.addChild(this.nodes, node, ob, 2, max_level)
            }
        });

        //console.log(this.nodes);
        return this.nodes;
  	}



    //產生一層的select list
    listTreeFormat(list, max_level=100, root_id=null) {
        this.nodes=[];
        let tree: (object)[] = [];
        
        list.forEach(node => {

            //ob 為準備放入nodes裡面的物件(已格式化), node為原物件(未進行格式處理)
            let ob = {
                'data': node,
                'id': node.id,
                'name': node.name
            };
            //格外判斷資訊
            ob.data['level'] = 1;
            ob.data['hasChild'] = 0;
            
            if (node.parent_id == root_id ){
                this.nodes.push(ob);
            }else{
                this.addListChild(this.nodes, node, ob, 2, max_level)
            }
        });

        //console.log(this.nodes);
        return this.nodes;
    }


    //為tree nodes child 新增ob object
    addListChild(nodes_array, node, ob, level, max_level) {

        nodes_array.map((item, index, array)=>{

            if(item.id==node.parent_id){
                ob.data.level = item.data.level+1;
                if(ob.data.level>max_level){
                    //console.log("over");
                    return;
                }
                item.data.hasChild = 1;
                ob.name = item.name+'/'+ob.name; 
                this.nodes.push(ob);
            }
        });
    }
}
