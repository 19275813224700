
<!-- 行銷活動(desktop) 
<ng-container *ngIf="!mobile">
	<ul *ngIf="level==1" class="nav nav-sale level-1">
		<li class="nav-item">
			<a class="nav-link">
				<span class="nav-text">Sale</span>

				第二層 
				<ul class="nav nav-sale level-2" [ngClass]="{'has-banner':discount_event_menu_banner}">

					全館優惠 
					<li class="nav-item">
						<a class="nav-link">
							<span class="nav-text">{{ 'home.menu.discount_shop' | translate }}</span>
						</a>
						第三層 
						<ul class="nav nav-sale level-3">
							<li class="nav-item" *ngFor="let discount_event of discount_events.shop">
								<a class="nav-link" [routerLink]="['/discount', discount_event.id]">
									<span class="nav-text">{{ discount_event.name }}</span>
								</a>					
							</li>
						</ul>
					</li>
					限定商品優惠
					<li class="nav-item">
						<a class="nav-link">
							<span class="nav-text">{{ 'home.menu.discount_product' | translate }}</span>
						</a>
						第三層 
						<ul class="nav nav-sale level-3">
							<li class="nav-item" *ngFor="let discount_event of discount_events.product">
								<a class="nav-link" [routerLink]="['/discount', discount_event.id]">
									<span class="nav-text">{{ discount_event.name }}</span>
								</a>					
							</li>
						</ul>
					</li>

					目錄廣告 
					<li *ngIf="discount_event_menu_banner" class="nav-banner"><img [src]="filepath+fileService.getThumbnail(discount_event_menu_banner.filepath,'s')" alt=""></li>
				</ul>
			</a>

		</li>
	</ul>
</ng-container>
-->

<!-- 搜尋 -->
<div class="search">
	<nb-icon icon="search"></nb-icon>
	<input type="search" [(ngModel)]="keyword" (keyup.enter)="webSearch()" class="form-control">
</div>

<!-- 分類目錄 -->
<ng-container>
	<ul class="menu-sub nav flex-column">
		<li class="nav-item dropdown" *ngFor="let item of list; let i=index">
			<a *ngIf="item.link" [routerLink]="item.link" class="nav-link" (click)="outputEvent()">{{ item.code | translate }}<nb-icon icon="arrow-ios-downward"></nb-icon></a>
			<a *ngIf="!item.link" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">{{ item.code | translate }}<nb-icon icon="arrow-ios-downward"></nb-icon></a>

			<!-- 第二層 -->
			<ul class="dropdown-menu" *ngIf="item.children.length>0">
				<li class="nav-item" *ngFor="let child of item.children">
					<a *ngIf="child.link" [routerLink]="child.link" class="nav-link" (click)="outputEvent()">{{ child.code | translate }}</a>
					<span *ngIf="!child.link" class="nav-link nav-label">{{ child.code | translate }}</span>
				</li>
			</ul>
		</li>
		
	</ul>

	<!-- 登出 -->
	<ng-container *ngIf="user">
		<a (click)="authService.logout()" class="nav-link mt-2">登出</a>
	</ng-container>

</ng-container>






