<!-- <div class="form-group mb-0">
  <label>{{ 'lang.select' | translate }}</label>  
	<nb-select placeholder="{{ 'lang.select' | translate }}" [(ngModel)]="currentLang" (selectedChange)="useLanguage(currentLang)">
    <nb-option [value]=lang *ngFor="let lang of languageService.langs">{{ 'lang.'+lang | translate }}</nb-option>
	</nb-select>
</div>
 -->

<!-- <nb-icon icon="globe-outline" class="mr-1"></nb-icon> -->
<img src="/assets/images/icon/globe-outline.svg" class="icon mr-1" width="18">{{ 'lang.select' | translate }}：
<div class="dropdown">
  <a class="dropdown-toggle" [ngClass]="{'show': toggle}" (click)="dropdownToggle()">
    <ng-container ngif*="currentLang == 'zh-tw">
    	<img src="/assets/images/icon/taiwan.jpg" alt="">
    </ng-container>
  </a>

  <div class="dropdown-menu" [ngClass]="{'show': toggle}">
    <a class="dropdown-item" *ngFor="let lang of languageService.langs" (click)="useLanguage(lang)">{{ 'lang.'+lang | translate  }}</a>
  </div>
</div>