
<!-- banner底圖, 底色 -->
<!-- <ng-container *ngIf="customClass=='home-top owl-nav-inside-bg'">
  <ng-container *ngFor="let slide of list; index as i">
    <div
      *ngIf="!mobile && i == startPosition"
      class="home-top-bg"
      [ngStyle]="{'background-image':'url('+filepath+slide.o_filepath+')'}"
    ></div>
    <div
      *ngIf="mobile && i == startPosition"
      class="home-top-color"
      [ngStyle]="{'background-image':'linear-gradient(#'+slide.m_color_code+', white)'}"
    ></div>
  </ng-container>
</ng-container> -->

<!-- 輪播banner -->
<div class="owl-dots-inside-bottom" [ngClass]="customClass">
  <owl-carousel-o [options]="customOptions" (translated)="getPassedData($event)">
   
   	<ng-container *ngFor="let slide of list">
    	<ng-template carouselSlide [id]="slide.id">
    		<div class="banner-item">
      		<ng-container *ngIf="slide.file_type=='image'">

      			<ng-container [ngSwitch]="slide.link_type">
      				<ng-container *ngSwitchCase="'in'">
      					<a [routerLink]="[slide.url]">
	        				<img *ngIf="!mobile || !slide.m_filepath" [src]="filepath+slide.filepath" [alt]="slide.name" [title]="slide.name">
            			<img *ngIf="mobile && slide.m_filepath"[src]="filepath+slide.m_filepath" [alt]="slide.name" [title]="slide.name">
            		</a>
      				</ng-container>
      				<ng-container *ngSwitchCase="'out'">
      					<a href="{{ slide.url }}">
	        				<img *ngIf="!mobile || !slide.m_filepath" [src]="filepath+slide.filepath" [alt]="slide.name" [title]="slide.name">
            			<img *ngIf="mobile && slide.m_filepath"[src]="filepath+slide.m_filepath" [alt]="slide.name" [title]="slide.name">
            		</a>
      				</ng-container>
      				<ng-container *ngSwitchDefault>
      					<img *ngIf="!mobile || !slide.m_filepath" [src]="filepath+slide.filepath" [alt]="slide.name" [title]="slide.name">
	            	<img *ngIf="mobile && slide.m_filepath"[src]="filepath+slide.m_filepath" [alt]="slide.name" [title]="slide.name">
      				</ng-container>
      			</ng-container>
	      		
        	</ng-container>

        	<ng-container *ngIf="slide.file_type=='video'">
        		<video autoplay>
    					<source [src]="filepath+slide.filepath" type="video/mp4">
  					</video>
        	</ng-container>
        </div>
    	</ng-template>
    </ng-container>
   
  </owl-carousel-o>
</div>