<span class="app-tag" [class]="type">
    <svg *ngIf="type === 'tag'"
        xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16" height="1em" width="1em">
        <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"/>
        <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"/>
    </svg>
    <svg *ngIf="type === 'error'"
        viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" class="si-glyph si-glyph-button-error" height="1em" width="1em">
        <g fill="#7F191E" fill-rule="evenodd">
            <path d="M14.646 2.371c-3.111-3.11-8.177-3.111-11.288 0-3.111 3.112-3.11 8.177 0 11.289 3.111 3.11 8.176 3.111 11.288 0 3.112-3.113 3.111-8.177 0-11.289ZM4.587 12.431C2.148 9.993 2.146 6.028 4.58 3.594c2.434-2.435 6.399-2.432 8.838.006 2.438 2.438 2.439 6.404.006 8.838-2.436 2.434-6.4 2.431-8.837-.007Z" class="si-glyph-fill"/>
            <path d="M11.164 11.063a.557.557 0 0 1-.388-.141L9.009 9.157l-1.695 1.695a.622.622 0 0 1-.423.146.842.842 0 0 1-.603-.265c-.221-.22-.27-.438-.271-.58a.554.554 0 0 1 .14-.4l1.724-1.725-1.68-1.678c-.152-.153-.279-.627.12-1.025.225-.225.446-.272.593-.272.183 0 .32.072.387.141L9.009 6.9l1.696-1.696a.624.624 0 0 1 .424-.146.84.84 0 0 1 .604.266c.378.379.302.81.131.98L10.138 8.03l1.737 1.736c.067.068.146.22.146.424a.834.834 0 0 1-.265.602c-.225.224-.445.271-.592.271Z" class="si-glyph-fill"/>
        </g>
    </svg>
    <ng-content></ng-content>
</span>