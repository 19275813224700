import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagComponent } from './tag/tag.component';
import { DisplayNumberWithCommasPipe } from './pipes/display-number-with-commas.pipe';

@NgModule({
  declarations: [
    TagComponent,
    DisplayNumberWithCommasPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TagComponent,
    DisplayNumberWithCommasPipe,
  ],
})
export class ShareUIModule { }
