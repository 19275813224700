import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, retry, debounceTime, finalize } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';
//import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import swal, { SweetAlertOptions } from 'sweetalert2';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
import { HttpService } from '../services/http.service';

@Injectable({
  providedIn: 'root'
})

export class ErrorNotifierInterceptor implements HttpInterceptor {

  constructor(
    private router:Router, 
    //private cookieService:CookieService,
    private logger: NGXLogger,
    //private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public httpService: HttpService,

  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    //this.logger.info('error start');
    return next.handle(request).pipe(
      tap(
        event => { },
        error => {
          if (error instanceof HttpErrorResponse) {

            //1. 非系統API回傳格式 不處理 log 錯誤訊息
            let reg = RegExp(environment.APIUrlReg);
            if(!reg.test(error.url)){
              this.logger.warn({
                name: 'response error',
                description: '非系統API錯誤',
                response: error,
              });

              //this.spinner.hide();
              return Observable.create(observer => observer.error(error));
            }


            //2. 系統API錯誤處理
            let err_info = error.error;

            switch (error.status) {

              //權限問題 token (token過期不在此處理)
              case 419: 
                if(err_info.err_code != 'TOKEN_EXPIRE'){
                  this.logger.warn({
                    name: 'response error',
                    description: '419 TOKEN權限問題',
                    error: err_info,
                    response: error,
                  });

                  //沒登入權限 需跳出警示窗
                  if(err_info.err_code == 'NO_LOGIN_PERMISSION' || err_info.err_code == 'NOT_YET_BE_CONFIRMED'){
                    //let msg = this.translateService.instant('error.code.'+err_info.err_code);
                    let msg = err_info.err_code
                    swal.fire({
                      title: 'Error!',
                      text: msg,
                      icon: 'error',
                      confirmButtonText: 'YES'
                    }).then((success)=>{
                      localStorage.removeItem('token');
                      //this.cookieService.deleteCookie('token');
                      this.router.navigate(['/auth/admin/login']);
                      //this.openDialog('/');
                    });

                  //token 失效 直接到登入頁
                  }else{
                    this.router.navigate(['/auth/admin/login']);
                    //this.router.navigate(['/login']);
                    //this.openDialog('/');
                  }
                }
              
              break;

              case 422: //欄位有誤
              case 403: //操作錯誤
              case 500: //其他問題
                this.logger.warn({
                  name: 'response error',
                  description: '422 403 500 系統內部錯誤',
                  error: err_info,
                  response: error,
                });

                //let msg = this.translateService.instant('error.code.'+err_info.err_code);
                //let msg = err_info.err_msg
                swal.fire({
                    title: `[ERROR ${error.status}] ${err_info.err_msg}`,
                    text: err_info.err_code,
                    icon: 'error',
                    confirmButtonText: 'YES'
                });
              break;

              case 404:
                this.logger.warn({
                  name: 'response error',
                  description: '404 找不到此API',
                  error: err_info,
                  response: error,
                });
                this.router.navigate(['/error404']);
              break;

              case 200:
              	this.logger.warn({
                  name: 'response error',
                  description: '200',
                  response: error,
                });
                
              break;
                      
            }//end of switch


            // 以错误的形式结束本次请求
            //this.spinner.hide();
            return Observable.create(observer => observer.error(error));
          }//end of if
        }//end of error
      ), finalize(() => {
            
            this.httpService.requestCount--;
            this.logger.debug('interceptor finial:'+this.httpService.requestCount+', status:'+this.httpService.loadingOpen);

 
            if(this.httpService.requestCount<=0){
                this.spinner.hide();
                this.httpService.requestCount=0;
            }
            //this.spinner.hide();
      }


    ));//end of tap & pipe

  }


}