<nb-layout>
 	<nb-layout-header fixed>
 		<div class="container-fluid">
	 		<div class="row">
	 			<div class="col-1">
	 				<button class="btn btn-primary" (click)="toggle()"><nb-icon icon="menu-2-outline"></nb-icon></button>
	 			</div>
	 			<div class="col-8">
	 				Labspace 商城後台
	 			</div>
	 			<div class="col-2">
	 				<div *ngIf="user_info">Hi, {{ user_info.name }}</div>
	 			</div>
	 			<div class="col-1">
	 				<button type="button" class="btn" (click)="authService.logout('auth/admin/login')">
	 					<nb-icon icon="power-outline" status="danger" ></nb-icon>
	 				</button>
	 			</div>
	 		</div>
	 	</div>
 	</nb-layout-header>

	<nb-layout-footer>
		Labspace @copyright
	</nb-layout-footer>

 	<nb-layout-column>

 		<router-outlet></router-outlet>
 	</nb-layout-column>

 	<nb-sidebar>
 		<app-admin-menu></app-admin-menu>
 	</nb-sidebar>
</nb-layout>
