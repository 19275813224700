import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class MetaPixelService {

  constructor(
    @Inject(DOCUMENT) private _document: Document,
		@Inject(PLATFORM_ID) private platformId: Object
  ) { }

  setupScript(renderer2: Renderer2) {
    let script = renderer2.createElement('script');
    script.text = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${environment.MetaPixelID}');
      fbq('track', 'PageView');
    `;
    renderer2.appendChild(this._document.head, script);
    
    let noscript = renderer2.createElement('noscript');
    let img = renderer2.createElement('img');
    img.height = 1;
    img.width = 1;
    img.style = "display:none";
    img.src = `https://www.facebook.com/tr?id=${environment.MetaPixelID}&ev=PageView&noscript=1`;
    noscript.appendChild(img)
    renderer2.appendChild(this._document.head, noscript);
  }

  addToCart(skus) {
		if(isPlatformBrowser(this.platformId)){
      try {
        skus.forEach(sku => {
          fbq('track', 'AddToCart', {currency: "TWD", value: sku.member_price});
        })
      } catch (error) {
        console.error('MetaPixelService#addToCart', skus, error)
      }
    }
  }

  purchase(order_number, order_total) {
		if(isPlatformBrowser(this.platformId)){
      fbq('track', 'Purchase', {currency: "TWD", value: order_total});
    }
  }

}
